import { NgModule } from '@angular/core';
import {
    MatTooltipModule, MatButtonModule, MatToolbarModule, MatSidenavModule, MatFormFieldModule, MatChipsModule,
    MatInputModule, MatProgressSpinnerModule, MatIconModule, MatCardModule, MatListModule, MatRadioModule, MatDatepickerModule, MatSelectModule, MatCheckboxModule,
    MatDialogModule, MatTableModule, MatSortModule, MatDividerModule, MatExpansionModule, MatBottomSheetModule
} from '@angular/material';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS,
    MatMomentDateModule,
    MomentDateAdapter
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";

@NgModule({
  imports: [MatTooltipModule, MatButtonModule, MatToolbarModule, MatSidenavModule, MatFormFieldModule, MatChipsModule, MatInputModule, MatProgressSpinnerModule,
      MatIconModule, MatCardModule, MatListModule, MatRadioModule, MatDatepickerModule, MatMomentDateModule, MatSelectModule, MatCheckboxModule, MatDialogModule,
      MatTableModule, MatSortModule, MatDividerModule, MatExpansionModule, MatBottomSheetModule, MatMomentDateModule],
  exports: [MatTooltipModule, MatButtonModule, MatToolbarModule, MatSidenavModule, MatFormFieldModule, MatChipsModule, MatInputModule, MatProgressSpinnerModule,
      MatIconModule, MatCardModule, MatListModule, MatRadioModule, MatDatepickerModule, MatMomentDateModule, MatSelectModule, MatCheckboxModule, MatDialogModule,
      MatTableModule, MatSortModule, MatDividerModule, MatExpansionModule, MatBottomSheetModule, MatMomentDateModule],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'default'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
    ]
})
export class MaterialModule {}
