import {
    ChangeDetectorRef,
    Component,
    HostListener, Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { Meta } from "@angular/platform-browser";
import {
    landingPageObjects,
    currency,
    miniWebsite,
    preference,
    schedulerLink,
    ExistingSessionObject,
    loginRegisterPopUpObject
} from "./common-classes/app-objects.model";
import {BusinessService} from "./business.service";
import {Title} from "@angular/platform-browser";
import {ClientInfoService} from "./scheduler/client-info/client-info.service";
import {of, Subscription} from "rxjs/index";
import {AuthService} from "./auth/auth.service";
import {ActivatedRoute, NavigationEnd, Params, Router, RouterStateSnapshot} from "@angular/router";
import {LocaleService} from "./locale.service";
import * as moment from "moment";
import {LabelMapService} from "./common-classes/label-map.service";
import {ResponsiveService} from "./responsive.service";
import {ClientAccountService} from "./client-account/client-account.service";
import {MatSidenav} from "@angular/material/sidenav";
// @ts-ignore
import {version} from '../../package.json';
import '../modernizr.js';
import {error} from "@angular/compiler/src/util";
import {DOCUMENT, registerLocaleData} from "@angular/common";
import {TagsService} from "./tags.service";
import {StyleSheetService} from './styleSheetService';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorModalComponent} from './modals/error-modal/error-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {SessionService} from './session.service';
import {OnloadService} from './onload.service';
import {SchedulerPreferenceService} from './scheduler-preference.service';
import {MiniWebsiteService} from './mini-website.service';
import {SchedulerLinkService} from './scheduler-link.service';
import {ResetPasswordComponent, ResetPasswordModalData} from './auth/reset-password/reset-password.component';
import {mod} from 'ngx-bootstrap/chronos/utils';
import {LoginComponent} from "./auth/login/login.component";
import {RegisterComponent} from "./auth/register/register.component";
declare let pushToParentPage: Function;
declare let gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  business: {businessId: number, businessName: string};
  miniWebsite: miniWebsite;
  currentDate: Date = new Date();
  activeYear: number = this.currentDate.getFullYear();
  preference: preference;
  schedulerLink: schedulerLink;
  isLoading: boolean = true;
  errorLoading: boolean = false;
  errorMessage: string = null;
  isAuthenticated: boolean;
  authSubscription: Subscription;
  state: RouterStateSnapshot;
  paramsSubscription: Subscription;
  embeddedSubscription: Subscription;
  version: string = version;
  params: Params;
  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  openSideNavSubscription: Subscription;
  isEmbedded: boolean = this.responsiveService.isEmbedded;
  sidenavPosition: string = 'start';
  openRegistrationModal: boolean = false; // LHB 07/13/2020 TT-6785
  bookingNotAllowedSubscription = new Subscription(); // LHB 2/25/2021 TT-7431
  constructor(private businessService: BusinessService,
              private labelMapService: LabelMapService,
              private titleService: Title,
              private clientInfoService: ClientInfoService,
              private clientAccountService: ClientAccountService,
              private authService: AuthService,
              private onloadService: OnloadService,
              private sessionService: SessionService,
              private meta: Meta,
              private router: Router,
              private route: ActivatedRoute,
              private localeService: LocaleService,
              private responsiveService: ResponsiveService,
              private cdRef: ChangeDetectorRef,
              private tagsService: TagsService,
              private styleSheetService: StyleSheetService, private dialog: MatDialog,
              private schedulerPreferenceService: SchedulerPreferenceService,
              private miniWebsiteService: MiniWebsiteService,
              private schedulerLinkService: SchedulerLinkService,
              @Inject(DOCUMENT) private _document: HTMLDocument){
      this.state = router.routerState.snapshot;
      let locale = 'en-US';
      let googleAnalyticsCode = null;
      try {
          locale = sessionStorage.getItem('locale');
          registerLocaleData(locale);
          googleAnalyticsCode = sessionStorage.getItem("googleAnalyticsCode");
          if(sessionStorage.getItem("browserTimezone") === undefined ||
              sessionStorage.getItem("browserTimezone") === null ||
              sessionStorage.getItem("browserTimezone") === ""){ // LHB 08/20/2020 fix for TT-6921
              let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              sessionStorage.setItem("timezone", timezone);
              sessionStorage.setItem("browserTimezone", timezone); //TT-6286
          }
      } catch (e) {

      }
      localeService.registerCulture(locale);
      if(googleAnalyticsCode !== null && googleAnalyticsCode !== "") {
          this.router.events.subscribe(event => {
              if (event instanceof NavigationEnd) {
                  let pagePath = event.urlAfterRedirects;
                  if(pagePath = "/")
                      pagePath = 'scheduler';

                  var code = sessionStorage.getItem("googleAnalyticsCode")
                  gtag('config', code,
                      {
                          'page_path': pagePath,
                          'page_location': window.location.href
                      }
                  );
                  pushToParentPage('visit', pagePath, window.location.href);
              }
          })
      }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.responsiveService.setInnerWidth();
  }

  determineSideNavPosition(){
      if(!this.isEmbedded && (this.miniWebsite.logoAlign === 'left' || this.miniWebsite.logoAlign === 'center')){
        this.sidenavPosition = 'end'
          this.cdRef.detectChanges();
      }
  }

  changeLang(language){
      moment.locale(sessionStorage.getItem('locale'));
      this.localeService.registerCulture(sessionStorage.getItem('locale'))
  }


  createErrorPageLayout(errorType: string, errorMessage?: string){
      this.businessService.landingPageObjectErrorLoading.next(false);
      this.errorLoading = true;
      this.miniWebsite = this.miniWebsiteService.createErrorMiniWebsite(errorType);
      this.titleService.setTitle(this.miniWebsite.pageTitle);
      if (errorMessage) {
          if (errorType === 'HANDLE_NOT_FOUND')
              this.errorMessage = 'We could not find a booking site with the webhandle: ' + errorMessage + '. Please check your url for typos.';
          else
            this.errorMessage = errorMessage;
      } else if(this.businessService.business && this.businessService.business.status !== 'ACTIVE')
          this.errorMessage = 'This scheduling account is no longer active.'
      else {
          switch (errorType) {
              case 'UNPUBLISHED_BOOKING_SITE':
                  this.errorMessage = 'Bookings on this website have been disabled. Please check with the business to see when bookings will be re-enabled.';
                  break;
              case 'HANDLE_NOT_FOUND':
                  this.errorMessage = 'We could not find a booking site at this URL. Please check your url for typos.';
                  break;
              default:
                  this.errorMessage = 'We could not load the requested scheduler at this time. Please refresh the page and try again. If you continue to experience issues, please reach out to support@timetap.com';
          }

      }
      this.isLoading = false;
  }

  paramsForLoadingUnpublished(): boolean{
      let waitlistInviteId = this.params["waitlistInviteId"];
      let waitlistHash = this.params["waitlistHash"];
      let removeWaitlist = this.params["removeWaitlist"];
      let apptHash = sessionStorage.getItem('apptHash');
      let disclaimerFormUUID = null;
      if (this.sessionService.sessionObject)
          disclaimerFormUUID = this.sessionService.sessionObject.parmDisclaimerFormUUID;
      let invoiceHash = this.params["invoiceHash"];
      if(this.sessionService.sessionObject && this.sessionService.sessionObject.parmInvoiceHash)
        invoiceHash = this.sessionService.sessionObject.parmInvoiceHash;
      let url = this.router.url;

      let publishOptional = false;
      if(disclaimerFormUUID !== undefined && disclaimerFormUUID !== null && disclaimerFormUUID !== '')
          publishOptional = true;
      if(invoiceHash !== undefined && invoiceHash !== null && invoiceHash !== '')
          publishOptional = true;
      if(waitlistInviteId !== undefined && waitlistInviteId !== null && waitlistInviteId !== "null" && waitlistInviteId !== '')
          publishOptional = true;
      if(waitlistHash !== undefined && waitlistHash !== null && waitlistHash !== "null" && waitlistHash !== '' && removeWaitlist == "true")
          publishOptional = true;
      if(url.indexOf('waitlist/claim') !== -1 || url.indexOf('waitlist/remove') !== -1)
          publishOptional = true;
      if(apptHash !== undefined && apptHash !== null && apptHash !== '' && window.location.href.indexOf('businessWeb/web/') !== -1)
          publishOptional = true;
      return publishOptional;
  }

  getLandingPageObjects() {
    this.isLoading = true;
    const handle = sessionStorage.getItem('handle'); // LHB 1/5/2021 TT-7263
    if (!handle || handle === ""){
        this.createErrorPageLayout('HANDLE_NOT_FOUND');
    }  else {
        this.onloadService.getLandingPageObjects(this.paramsForLoadingUnpublished())
            .subscribe((landingPageObjects: landingPageObjects) => {
                if (this.businessService.business.status !== 'ACTIVE') {
                    this.createErrorPageLayout('inactive')
                } else {
                    this.preference = this.schedulerPreferenceService.schedulerPreference;
                    this.schedulerLink = this.schedulerLinkService.schedulerLink;
                    this.miniWebsite = this.miniWebsiteService.miniWebsite;
                    this.determineSideNavPosition();
                    this.redirectToWaitlist();
                    this.showResetPwd();
                    this.isLoading = false;
                    this.businessService.landingPageObjectLoaded.next(true);
                }
            }, (error: HttpErrorResponse) => {
                console.log('error', error);
                const errorMessage = error.error.message;
                let errorType = 'errorLoading';
                if (error.error.result)
                    errorType = error.error.result;
                this.createErrorPageLayout(errorType, errorMessage)
            });
    }

  }
  redirectToDisclaimerForm(disclaimerFormUUID: string){
    if(disclaimerFormUUID !== undefined && disclaimerFormUUID !== null && disclaimerFormUUID !== ''){
            this.router.navigate(['forms/' + disclaimerFormUUID]);
    }
  }

  redirectToInvoiceDetail(invoiceHash: string, privateUrlHash: string){
    if(invoiceHash !== undefined && invoiceHash !== null && invoiceHash !== '' && privateUrlHash !== undefined){
        if(window.location.href.indexOf(privateUrlHash) !== -1 ){
            this.router.navigate(['invoices/' + invoiceHash]);
        }
    }
  }

  removePasswordRequestIdFromURL() {
      if (window.location.href.indexOf('?passwordRequestId=') !== -1) {
          const index = window.location.href.indexOf('?passwordRequestId=');
          const newUrl = window.location.href.substring(0, index);
          window.history.pushState(null, 'Reset Password', newUrl);
      }
  }



  showResetPwd() {
      let autoOpenReset = this.params["showResetPassword"];
      if(autoOpenReset){
          const dialogRef = this.dialog.open(RegisterComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(), width: this.responsiveService.getMaxWidthForModals(),
              data: {preference: this.preference, email: '', description: '', loadMyAccount: false, resetPwd:true, needAccount:false}});
      }else {
          let passwordRequestId = this.params["passwordRequestId"];
          if (passwordRequestId) {
              const dataToPass: ResetPasswordModalData = new ResetPasswordModalData();
              dataToPass.passwordRequestId = passwordRequestId;
              const dialogRef = this.dialog.open(ResetPasswordComponent, {
                  maxWidth: this.responsiveService.getMaxWidthForModals(),
                  width: this.responsiveService.getMaxWidthForModals(),
                  data: dataToPass
              });
              dialogRef.afterClosed().subscribe((modalData: ResetPasswordModalData) => {
                  if (modalData.success) {
                      this.removePasswordRequestIdFromURL();
                      if (modalData.openLogin) {
                          let loginTriggeredData: loginRegisterPopUpObject = new loginRegisterPopUpObject();
                          loginTriggeredData.loadMyAccount = true;
                          loginTriggeredData.calledFrom = 'RESET_PASSWORD';
                          loginTriggeredData.callSubscriptionNext = false;
                          loginTriggeredData.email = modalData.email;
                          loginTriggeredData.description = this.preference.labelMap.resetPwdSuccessMsg;
                          this.authService.loginCalled.next(loginTriggeredData);
                      }
                  }
              });
          }
      }
  }
  redirectToWaitlist(){
      let waitlistInviteId = this.params["waitlistInviteId"];
      let waitlistHash = this.params["waitlistHash"];
      let clientHash = this.params["clientHash"];
      let removeWaitlist = this.params["removeWaitlist"];
      if(waitlistInviteId !== undefined && waitlistInviteId !== null && waitlistInviteId !== "null" && waitlistInviteId !== ''){
              this.router.navigate(['waitlist/claim/' + waitlistInviteId]);
      }
      if(waitlistHash !== undefined && waitlistHash !== null && waitlistHash !== "null" && waitlistHash !== '' && removeWaitlist == "true"){
          this.router.navigate(['waitlist/remove/' + waitlistHash]);
      }
      if(clientHash !== undefined && clientHash !== null && clientHash !== "null" && clientHash !== ''){
          this.router.navigate(['waitlist/claimTimes/' + clientHash]);
      }
  }
  redirectToApptDetail(){
      let apptHash = sessionStorage.getItem('apptHash');
      if(apptHash !== undefined && apptHash !== null && apptHash !== '' && (window.location.href.indexOf('businessWeb/web/') !== -1 || window.location.href.indexOf('businessWeb/profile.do') !== -1)){
          this.router.navigate(['appts/' + apptHash]);
      }
  }

  loadApp() {
      this.responsiveService.init();
      this.embeddedSubscription = this.responsiveService.embeddedScheduler.subscribe(embedded => {
          this.isEmbedded = embedded;
      });
      this.paramsSubscription = this.route.queryParams.subscribe((params: Params) => {
          this.params = params;
          this.schedulerPreferenceService.setParameterPassedFields(params);
          let locale = params["locale"];
          //showHeader=0 is for embed
          // showHeader=1 is to show full site
          let showHeader = "1";
          let showHeaderSessionStorage = sessionStorage.getItem("showHeader")
          let showHeaderParams = params["showHeader"];
          // console.log("showHeader A " + showHeader);
          // console.log("showHeaderSessionStorage A " + showHeaderSessionStorage);
          // console.log("showHeaderParams A " + showHeaderParams);
          if(params["showHeader"] !== undefined){
              sessionStorage.setItem("showHeader", params["showHeader"]);
              showHeader = params["showHeader"];
              sessionStorage.setItem("showHeader", showHeader);
              // console.log("showHeader C " + showHeader);
          } else if(showHeaderSessionStorage !== null && showHeaderSessionStorage !== "null"){
              showHeader = sessionStorage.getItem("showHeader");
              // console.log("showHeader B " + showHeader);
          }
          this.responsiveService.setEmbeddedValue(showHeader);
          if(locale !== undefined && locale !== null){
              sessionStorage.setItem('locale', locale);
              this.changeLang(locale);
          }
      });
      console.log('Calling redirect');
      this.redirectToApptDetail();

      this.authSubscription = this.authService.authChange.subscribe(authStatus => {
          this.isAuthenticated = authStatus;
          if(!this.isAuthenticated && this.router.url.indexOf('my-account')!==-1){
              this.preference.schedulerPreferenceFieldDefnList = this.clientInfoService.initialConfigurationOfSchedulerFields(this.preference.schedulerPreferenceFieldDefnListOriginal, this.preference);
              this.router.navigate(['scheduler']);
          }
      });

      this.openSideNavSubscription = this.businessService.sidenavChange.subscribe(openStatus => {
          if(this.sidenav !== undefined){
              this.sidenav.toggle()
          }
      });

      // if(localStorage.getItem('existingSessionToken')!== null){
      this.sessionService.checkValidSession(localStorage.getItem('existingSessionToken'))
          .subscribe((sessionObject: ExistingSessionObject) => {
              if(sessionObject !== null && sessionObject.clientAccount !== null && sessionObject.clientAccount.clientList.length > 0){
                  if(sessionObject.passwordLogin){
                      this.isAuthenticated = true;
                      this.authService.updateToken(sessionObject.sessionToken);
                      this.authService.authenticatedSuccessfully(sessionObject.clientAccount, false);
                  } else {
                      localStorage.setItem('existingSessionToken', sessionStorage.getItem('token'));
                      this.clientAccountService.setLoggedInClient(sessionObject.clientAccount);
                  }
              } else {
                  localStorage.setItem('existingSessionToken', sessionStorage.getItem('token'));
              }
              this.schedulerPreferenceService.setParametersFromExistingSessionObject(sessionObject);
              this.authService.removeBackofficeSessionTokenFromURL();
              this.redirectToInvoiceDetail(sessionObject.parmInvoiceHash, sessionObject.privateUrlHash);
              this.redirectToDisclaimerForm(sessionObject.parmDisclaimerFormUUID);

              this.getLandingPageObjects();
          }, error => {
              this.getLandingPageObjects();
          })
  }

  showTurnOnAllowCookiesMessage() {
      let errors = {'cookiesNotAllowed': true};
      const dialogRef = this.dialog.open(ErrorModalComponent, {
          maxWidth: this.responsiveService.getMaxWidthForModals(),
          data: {preference: this.preference, errorType: 'cookiesNotAllowed', errorData: errors}
      })
  }

  ngOnInit() {
      try {
          const locale = sessionStorage.getItem('locale');
          this.loadApp();
      } catch (e) {
          this.showTurnOnAllowCookiesMessage();
      }
      // } else {
      //     localStorage.setItem('existingSessionToken', sessionStorage.getItem('token'));
      //     this.getLandingPageObjects();
      // }
      this.bookingNotAllowedSubscription = this.clientAccountService.movedFromDetailWhenBookingNotAllowed.subscribe(() => {
          this.createErrorPageLayout('UNPUBLISHED_BOOKING_SITE');
      })

  }
  ngOnDestroy(){
      this.authSubscription.unsubscribe();
      this.paramsSubscription.unsubscribe();
      this.embeddedSubscription.unsubscribe();
      this.openSideNavSubscription.unsubscribe();
      this.bookingNotAllowedSubscription.unsubscribe();
      sessionStorage.removeItem('backofficeSessionToken');
  }
}
