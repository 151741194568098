import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  Client,
  ClientAccount,
  ConfigListMap,
  CouponAPIReturnData,
  CSFilterParameterBean,
  movePanels,
  preference
} from '../../common-classes/app-objects.model';
import {BusinessService} from '../../business.service';
import {ClientAccountService} from '../../client-account/client-account.service';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {BookingSelectionObjectTypes, BookingSelectionOption} from '../../../models/BookingSelectionOption.model';
import {Group, GroupTypes} from '../../../models/Group.model';
import {ValueOptionModel} from '../../../models/ValueOption.model';
import {
  WaitList,
  WaitListRegistrationDateOptionValues,
  WaitListRegistrationTimeOptionValues
} from '../../../models/WaitList.model';
import {JoinWaitListService} from './join-wait-list.service';
import {SingleTimeSlot} from '../../scheduler/time/time-objects.model';
import {TimeService} from '../../scheduler/time/time.service';
import {MainObjectTypes} from '../../../models/MainObjectTypes.model';
import {AvailabilityService} from '../../booking-components/availability.service';
import {Reason, ReasonTypes} from '../../../models/Reason.model';
import {ReasonsService} from '../../reasons.service';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {ConfigListService} from '../../config-list.service';
import {Location} from '../../../models/Location.model';
import {Staff} from '../../../models/Staff.model';
import {MilitaryTimeConversion} from '../../../pipes/militaryTimeConversion.pipe';
import {schedulerPanel, SchedulerPanelValues} from '../../../models/SchedulerPanel.model';
import {ResponsiveService} from '../../responsive.service';
import {SelectedDateTimeObj} from '../../../models/SelectedDateTimeObj.model';
import {TimezoneService} from '../../scheduler/common-components/timezone/timezone.service';
import * as _ from 'lodash';
import {Subscription} from 'rxjs/index';
import {FormatFunctionsService} from '../../format-functions.service';

@Component({
  selector: 'app-join-wait-list',
  templateUrl: './join-wait-list.component.html',
  styleUrls: ['./join-wait-list.component.css']
})
export class JoinWaitListComponent implements OnInit, OnDestroy {
  preference: preference;
  clientAccount: ClientAccount = this.clientAccountService.loggedInClient;
  configList: ConfigListMap = this.configListService.configList;
  selectedClient: Client;
  joinWaitListTitle: string;
  joinWaitListDescription: string;
  waitListOptionsLoading: boolean;
  waitList: WaitList;
  waitLists: WaitList[];
  objectType: MainObjectTypes.WAIT_LIST = MainObjectTypes.WAIT_LIST
  joiningWaitList: boolean = false;
  bookingSelectionFlow: BookingSelectionOption[] = [];
  firstAvailableValueOption: ValueOptionModel = new ValueOptionModel('First Available', WaitListRegistrationDateOptionValues.FIRST_AVAILABLE, false);
  byDateValueOption: ValueOptionModel = new ValueOptionModel('By Specific Dates & Times', WaitListRegistrationDateOptionValues.DATE_TIME, false);
  waitListRegistrationDateOptions: ValueOptionModel[] = [];
  waitListRegistrationDateOption: ValueOptionModel;
  specificTimeValueOption: ValueOptionModel = new ValueOptionModel('Select from booked times', WaitListRegistrationTimeOptionValues.BOOKED_TIMES, false);
  customTimeRangeValueOption: ValueOptionModel = new ValueOptionModel('Enter custom time frame', WaitListRegistrationTimeOptionValues.CUSTOM_TIME, false);
  allDayTimeRangeValueOption: ValueOptionModel = new ValueOptionModel('Add the whole day', WaitListRegistrationTimeOptionValues.ALL_DAY, false);
  waitListRegistrationTimeOptions: ValueOptionModel[];
  waitListRegistrationTimeOption: ValueOptionModel;
  customTimeSlot: SingleTimeSlot;
  waitListRegistrationTimeOptionValues = WaitListRegistrationTimeOptionValues;
  waitListRegistrationDateOptionValues = WaitListRegistrationDateOptionValues;
  addingTime: boolean = false;
  addedTime: boolean = false;
  errorAddingTime: boolean = false;
  disableSave: boolean = false;
  joinWaitListBtn: string;
  panels: schedulerPanel[] = [];
  choosingWaitListTimes: boolean = true;
  enteringClientInfo: boolean = false;
  showConfirmation: boolean = false;
  clientInfoPanel: schedulerPanel;
  finalConfirmationPanel: schedulerPanel;
  CSFilterObj: CSFilterParameterBean;
  inputValues: any = [];
  joinWaitListHeaderClickSubscription: Subscription;
  noWaitListAbleReasons: boolean;
  showNoWaitListReasonsMsg: boolean;
  selectedTimezone: string;
  @ViewChild('timeslotMultiSelectComponent', {static: false}) timeslotMultiSelectComponent;
  constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private formatFunctionsService: FormatFunctionsService,
              private timeService: TimeService, private availabilityService: AvailabilityService, private reasonsService: ReasonsService,
              private schedulerService: SchedulerService, private joinWaitListService: JoinWaitListService, private cdRef: ChangeDetectorRef,
              private schedulerPreferenceService: SchedulerPreferenceService, private configListService: ConfigListService, private militaryTimeConversion: MilitaryTimeConversion,
              public responsiveService: ResponsiveService, private timezoneService: TimezoneService) {
    this.preference = schedulerPreferenceService.schedulerPreference;
  }

  movePanels(moveData: movePanels){
    this.joinWaitListHeaderClickSubscription = this.joinWaitListService.joinWaitListHeaderClicked.subscribe(() => {
      this.showConfirmation = false;
      this.enteringClientInfo = false;
      this.choosingWaitListTimes = true;
      this.availabilityService.availabilityFilterData = null;
      this.loadForm();
      this.joinWaitListHeaderClickSubscription.unsubscribe();
    })
    switch (moveData.panel.panel) {
      case SchedulerPanelValues.clientInfo:
        this.enteringClientInfo = false
        if (moveData.direction === 'back')
          this.choosingWaitListTimes = true;
        else if (moveData.direction === 'next') {
          this.showConfirmation = true;
          this.waitLists = moveData.waitLists
        }
        break;
      case SchedulerPanelValues.finalConfirmation:
        this.showConfirmation = false;
        this.enteringClientInfo = false;
        this.choosingWaitListTimes = true;
        this.availabilityService.availabilityFilterData = null;
        this.loadForm();
        break;
    }
  }

  setBtnLabel() {
    if (this.waitListRegistrationDateOption && this.waitListRegistrationDateOption.value === WaitListRegistrationDateOptionValues.FIRST_AVAILABLE)
      this.joinWaitListBtn = this.preference.labelMap.joinWaitListBtnFirstAvailable;
    else
      this.joinWaitListBtn = this.preference.labelMap.joinWaitListBtnSpecificTimes;
  }

  setTextProperties() {
    this.joinWaitListTitle = this.preference.labelMap.timePnlWaitlistTitle;
    this.joinWaitListDescription = this.preference.labelMap.joinWaitListDescription;
    this.setBtnLabel();
  }

  timeChanged(time: number, property: string) {
    this.customTimeSlot[property] = time;
    let minutes = 5;
    minutes = this.availabilityService.getApptDuration(this.waitList, this.objectType);
    switch (property) {
      case 'endTime':
        this.customTimeSlot.startTime = this.timeService.checkStartTimeBeforeEndTime(this.waitList.startDate, this.waitList.startDate, this.customTimeSlot.startTime, this.customTimeSlot.endTime, 'startTime', false, minutes);
        break;
      case 'startTime':
        this.customTimeSlot.endTime = this.timeService.checkStartTimeBeforeEndTime(this.waitList.startDate, this.waitList.startDate, this.customTimeSlot.startTime, this.customTimeSlot.endTime, 'endTime', false, minutes);
        break;
    }
  }

  storeTimeSlot(timeSlot: SingleTimeSlot) {
    if (timeSlot) {
      const added = this.joinWaitListService.storeTimeSlotOnWaitList(this.waitList, timeSlot);
      if(!added) { // If not added, then remove timeslot from waitList as well.
        const should_be_present = Object.entries(this.waitList.selectedTimes)
            .filter(([k, value]) => {
              return (
                  timeSlot.classSession.reasonId === value.calTimeSlot.classSession.reasonId
              );
            }).map((([k,v]) => v));
        this.waitList.selectedTimes = should_be_present;
      }
      if (this.waitListRegistrationTimeOption.value !== WaitListRegistrationTimeOptionValues.BOOKED_TIMES) {
        this.addingTime = false;
        if (added)
          this.addedTime = true;
        else
          this.errorAddingTime = true;
      }
    }
  }

  storeTimeSlots(timeSlots: SingleTimeSlot[]) {
    for (let i = 0, x = timeSlots.length; i < x; i++)
      this.storeTimeSlot(timeSlots[i]);
    this.configureOnloadAndChanges(this.waitList);
  }

  getTimezoneForDisplay(onload?: boolean){
    this.timezoneService.getTimezoneForDisplay(this.CSFilterObj)
        .subscribe((timezone: string) => {
          if (onload) {
            this.CSFilterObj = this.timezoneService.matchClientTZ(timezone, this.CSFilterObj);
            this.setWaitListRegistrationDateOption(this.waitListRegistrationDateOptions[0]);
          }
        });
  }

  timeZoneChanged(timezoneChangeEvent: {path: string, timezoneCode: string, timezoneId: number}){
    this.CSFilterObj.clientTimeZone = timezoneChangeEvent.timezoneCode;
    this.CSFilterObj.clientTimeZoneId = timezoneChangeEvent.timezoneId;
    this.waitList.waitListTZ = this.CSFilterObj.clientTimeZone;
    if (this.timeslotMultiSelectComponent)
      this.timeslotMultiSelectComponent.changeTimeZoneForTimeSlots();
    this.selectedTimezone = timezoneChangeEvent.timezoneCode;
  }

  changeTriggeredToCheckTimezoneDisplay(originalFilter: CSFilterParameterBean, updatedFilter: CSFilterParameterBean): boolean {
    let checkTimezone = false;
    if (!this.formatFunctionsService.arraysEqual(originalFilter.staffIdList, updatedFilter.staffIdList))
      return true;
    else if (!this.formatFunctionsService.arraysEqual(originalFilter.locationIdList, updatedFilter.locationIdList))
      return true;
    else if (!this.formatFunctionsService.arraysEqual(originalFilter.locationGroupIdList, updatedFilter.locationGroupIdList))
      return true;
    else
      return checkTimezone;
  }

  configureOnloadAndChanges(waitList: WaitList) {
    // Added to remove WaitList table when user change the class/service drop-down value, also clear the selected timeSlots
    if(waitList && event && event.type && event.currentTarget && event.type == 'click') {
      const target = event.currentTarget as HTMLElement;
      if(target.tagName == 'MAT-OPTION') {
        waitList.waitListDateList = [];
        waitList.selectedTimes = [];
      }
    }
    this.waitList = waitList;
    const originalCSFilter = _.cloneDeep(this.CSFilterObj);
    this.CSFilterObj = this.joinWaitListService.setCSFilterObject(this.bookingSelectionFlow, this.CSFilterObj);
    if (this.changeTriggeredToCheckTimezoneDisplay(originalCSFilter, this.CSFilterObj))
      this.getTimezoneForDisplay();
    this.joinWaitListService.setCurrentEvent(waitList);
    this.availabilityService.setMinMaxBookingDates(waitList.reason);
    this.checkDisableSave();
    this.cdRef.detectChanges();
  }

  checkLoadCompleted(counter: number, bookingFlowLength: number) {
    if (counter === bookingFlowLength) {
      if (this.noWaitListAbleReasons) {
        this.showNoWaitListReasonsMsg = false;
        if (this.bookingSelectionFlow[0].objectType === BookingSelectionObjectTypes.REASON) {
          this.showNoWaitListReasonsMsg = true;
        } else {
          let allSelectionsHaveOneValue = true;
          for (let i = 0, x = this.bookingSelectionFlow.length; i < x; i++) {
            if (this.bookingSelectionFlow[i].objectType === BookingSelectionObjectTypes.REASON) // reached reason, can assume all before have been checked
              break;
            else if (this.bookingSelectionFlow[i].options.length > 1)
              allSelectionsHaveOneValue = false;
          }
          if (allSelectionsHaveOneValue)
            this.showNoWaitListReasonsMsg = true;
        }
      }
      this.getTimezoneForDisplay(true);
      this.configureOnloadAndChanges(this.waitList);
      this.waitListOptionsLoading = false;
    }
  }

  setWaitListRegistrationDateOption(waitListRegistrationDateOption: ValueOptionModel) {
    this.waitListRegistrationDateOption = this.joinWaitListService.setWaitListRegistrationDateTimeOption(waitListRegistrationDateOption.value, this.waitListRegistrationDateOptions);
    if (!this.waitListRegistrationTimeOption && this.waitListRegistrationDateOption.value === WaitListRegistrationDateOptionValues.DATE_TIME) {
      for (let i = 0, x = this.waitListRegistrationTimeOptions.length; i < x; i++)
        if (this.waitListRegistrationTimeOptions[i].value === WaitListRegistrationTimeOptionValues.BOOKED_TIMES)
          this.setWaitListRegistrationTimeOption(this.waitListRegistrationTimeOptions[i]);
    }
    this.setBtnLabel();
    this.checkDisableSave();
  }

  setWaitListRegistrationTimeOption(waitListRegistrationDateTimeOption: ValueOptionModel) {
    this.waitListRegistrationTimeOption = this.joinWaitListService.setWaitListRegistrationDateTimeOption(waitListRegistrationDateTimeOption.value, this.waitListRegistrationTimeOptions);
    if (this.waitListRegistrationTimeOption.value === WaitListRegistrationTimeOptionValues.CUSTOM_TIME) {
      this.customTimeSlot = new SingleTimeSlot();
      this.customTimeSlot.startTime = 800;
      this.customTimeSlot.endTime = this.timeService.calculateEndTime(this.waitList.reason.visitMinutes, this.customTimeSlot.startTime, 'roundUp');
      this.customTimeSlot.selected = true;
    } else if (this.waitListRegistrationTimeOption.value === WaitListRegistrationTimeOptionValues.ALL_DAY) {
      this.customTimeSlot = new SingleTimeSlot();
      this.customTimeSlot.startTime = 0;
      this.customTimeSlot.endTime = 2400;
      this.customTimeSlot.selected = true;
    }

  }

  async getOptionsForWaitListChoices() {
    this.CSFilterObj = this.schedulerService.newCSFilterObj();
    let counter = 0;
    for (const bs of this.bookingSelectionFlow) {
      switch (bs.objectType) {
        case BookingSelectionObjectTypes.LOCATION:
          await new Promise((resolve, reject) => {
            this.businessService.getLocations(this.CSFilterObj).subscribe((locations: Location[]) => {
              bs.options = locations;
              bs.selectedOption = bs.options[0];
              this.waitList.location = bs.selectedOption;
              this.CSFilterObj.locationIdList = [bs.selectedOption.locationId];
              counter++;
              this.checkLoadCompleted(counter, this.bookingSelectionFlow.length);
              resolve();
            });
          })

          break;
        case BookingSelectionObjectTypes.STAFF:
          await new Promise((resolve, reject) => {
            this.businessService.getStaff(this.CSFilterObj).subscribe((staff: Staff[]) => {
              if (this.preference.staffNoPreference === 0 && staff.length > 1) {
                const noPreferenceStaff = new Staff(null, this.preference.labelMap.labelNoPreference);
                noPreferenceStaff.imageUrl = null;
                noPreferenceStaff.staffProfile = null;
                staff.unshift(noPreferenceStaff);
              }
              bs.options = staff;
              bs.selectedOption = bs.options[0];
              this.waitList.staff = bs.selectedOption;
              this.CSFilterObj.staffIdList = [bs.selectedOption.professionalId];
              counter++;
              this.checkLoadCompleted(counter, this.bookingSelectionFlow.length);
              resolve();
            });
          })
          break;
        case BookingSelectionObjectTypes.REASON:
          await new Promise((resolve, reject) => {
            this.reasonsService.getReasons(this.CSFilterObj, true).subscribe((reasonReturnData: CouponAPIReturnData) => {
              if (reasonReturnData.reasonAPIBeanList && reasonReturnData.reasonAPIBeanList.length > 0) {
                bs.options = reasonReturnData.reasonAPIBeanList;
                this.noWaitListAbleReasons = false;
              } else {
                const noReasonForBooking: Reason = new Reason();
                noReasonForBooking.createNoWaitListAbleReason(this.configList);
                bs.options = [noReasonForBooking];
                this.CSFilterObj.reasonIdList = [];
                this.noWaitListAbleReasons = true;
              }
              bs.selectedOption = bs.options[0];
              this.waitList.reason = bs.selectedOption;
              this.CSFilterObj.reasonIdList = [bs.selectedOption.reasonId];
              counter++;
              this.checkLoadCompleted(counter, this.bookingSelectionFlow.length);
              resolve();
            });
          });
          break;
        case BookingSelectionObjectTypes.GROUP:
          await new Promise((resolve, reject) => {
            this.businessService.getGroups(bs.groupType, this.CSFilterObj).subscribe((groups: Group[]) => {
              bs.options = groups;
              bs.selectedOption = bs.options[0];
              switch (bs.groupType) {
                case GroupTypes.LOCATION_GROUP:
                  this.waitList.locationSuperGroup = bs.selectedOption;
                  this.CSFilterObj.locationSuperGroupIdList = [bs.selectedOption.groupId];
                  break;
                case GroupTypes.LOCATION:
                  this.waitList.locationGroup = bs.selectedOption;
                  this.CSFilterObj.locationGroupIdList = [bs.selectedOption.groupId];
                  break;
                case GroupTypes.SERVICE_GROUP:
                  this.waitList.reasonSuperGroup = bs.selectedOption;
                  this.CSFilterObj.reasonSuperGroupIdList = [bs.selectedOption.groupId];
                  break;
                case GroupTypes.SERVICE:
                  this.waitList.reasonGroup = bs.selectedOption;
                  this.CSFilterObj.reasonGroupIdList = [bs.selectedOption.groupId];
                  break;
              }
              counter++;
              this.checkLoadCompleted(counter, this.bookingSelectionFlow.length);
              resolve();
            });
          });
          break;
      }
    }
    
  }

  setUpWaitListChoices() {
    this.waitListOptionsLoading = true;
    const bookingSelectionFlowValues = this.schedulerService.getPanelOrder(this.preference.appMode, this.preference.showServiceGroup, this.preference.showServiceSuperGroup);
    this.panels = this.schedulerService.configurePanels(true, bookingSelectionFlowValues);
    this.bookingSelectionFlow = [];
    for (let i = 0, x = bookingSelectionFlowValues.length; i < x; i++) {
      if (bookingSelectionFlowValues[i] === 'locations')
        this.bookingSelectionFlow.push(this.schedulerService.createLocationBookingOption());
      else if (bookingSelectionFlowValues[i] === 'staff')
        this.bookingSelectionFlow.push(this.schedulerService.createStaffBookingOption());
      else if (bookingSelectionFlowValues[i] === 'reasons')
        this.bookingSelectionFlow.push(this.schedulerService.createReasonBookingOption());
      else if (bookingSelectionFlowValues[i] === 'locationSuperGroups')
        this.bookingSelectionFlow.push(this.schedulerService.createGroupBookingOption(GroupTypes.LOCATION_GROUP));
      else if (bookingSelectionFlowValues[i] === 'locationGroups')
        this.bookingSelectionFlow.push(this.schedulerService.createGroupBookingOption(GroupTypes.LOCATION));
      else if (bookingSelectionFlowValues[i] === 'reasonSuperGroups')
        this.bookingSelectionFlow.push(this.schedulerService.createGroupBookingOption(GroupTypes.SERVICE_GROUP));
      else if (bookingSelectionFlowValues[i] === 'reasonGroups')
        this.bookingSelectionFlow.push(this.schedulerService.createGroupBookingOption(GroupTypes.SERVICE));
    }
    this.getOptionsForWaitListChoices();

  }

  checkDisableSave() {
    let disableSave = true;
    if (this.waitList.reason && this.waitList.reason.reasonId !== -1 && ((this.waitListRegistrationDateOption && this.waitListRegistrationDateOption.value === WaitListRegistrationDateOptionValues.FIRST_AVAILABLE) ||
        (this.waitList.waitListDateList && this.waitList.waitListDateList.length > 0)))
      disableSave = false;
    this.disableSave = disableSave;
    this.cdRef.detectChanges();
  }

  joinWaitList() {
    for (let i = 0, x = this.panels.length; i < x; i++) {
      switch (this.panels[i].panel) {
        case SchedulerPanelValues.locations:
          this.panels[i].formGroup.patchValue({location: this.waitList.location, preference: this.preference});
          this.inputValues = this.schedulerService.arrangeInputValues(this.inputValues, this.panels[i].formGroup.value, i, this.panels, this.CSFilterObj);
          break;
        case SchedulerPanelValues.staff:
          this.panels[i].formGroup.patchValue({staff: this.waitList.staff});
          this.inputValues = this.schedulerService.arrangeInputValues(this.inputValues, this.panels[i].formGroup.value, i, this.panels, this.CSFilterObj);
          break;
        case SchedulerPanelValues.reasons:
          this.panels[i].formGroup.patchValue({reason: this.waitList.reason});
          this.inputValues = this.schedulerService.arrangeInputValues(this.inputValues, this.panels[i].formGroup.value, i, this.panels, this.CSFilterObj);
          break;
        case SchedulerPanelValues.time:
          if (this.waitListRegistrationDateOption.value === WaitListRegistrationDateOptionValues.FIRST_AVAILABLE) {
            let firstAvailTime = this.availabilityService.createFirstAvailableTimeSlot(this.waitList.reason, this.waitList.staff, this.waitList.location);
            let selectedDateTimeObj: SelectedDateTimeObj = new SelectedDateTimeObj().createFromSingleTimeSlot(firstAvailTime, this.waitList.reason, this.waitList.staff, this.waitList.location, this.preference.labelMap.labelNoPreference);
            this.panels[i].formGroup.controls['selectedDateTime'].setValue(selectedDateTimeObj);
          } else {
            this.panels[i].formGroup = this.schedulerService.getPanelForm(this.panels[i].panel, this.preference.schedulerPreferenceFieldDefnList);
            for (let j = 0, y = this.waitList.selectedTimes.length; j < y; j++) {
              // @ts-ignore
              if (this.panels[i].formGroup.controls['selectedDateTime'].controls.clientStartTime.value === null) {
                this.panels[i].formGroup.controls['selectedDateTime'].setValue(this.waitList.selectedTimes[j]);
              } else {
                let count = 0;
                for (let prop in this.panels[i].formGroup.controls) {
                  count++;
                }
                let formControlName = 'selectedDateTime' + count;
                this.panels[i].formGroup.addControl(formControlName, this.schedulerService.getTimeSlotFormGroup());
                this.panels[i].formGroup.controls[formControlName].setValue(this.waitList.selectedTimes[j]);
              }
            }
          }
          this.inputValues = this.schedulerService.arrangeInputValues(this.inputValues, this.panels[i].formGroup.value, i, this.panels, this.CSFilterObj);
          break;
        case SchedulerPanelValues.clientInfo:
          this.clientInfoPanel = this.panels[i];
          break;
        case SchedulerPanelValues.finalConfirmation:
          this.finalConfirmationPanel = this.panels[i];
          break;
      }
      this.choosingWaitListTimes = false;
      this.enteringClientInfo = true;
    }
  }

  checkShowDateAndTimeSlotSelector() {
    return this.waitList.reason && this.waitList.reason.reasonId !== -1 && this.waitList.reason.reasonType !== ReasonTypes.COURSE && this.waitListRegistrationDateOption && this.waitListRegistrationDateOption.value === WaitListRegistrationDateOptionValues.DATE_TIME;
  }

  checkShowDateAndTimeTable() {
    return this.waitList.reason && this.waitList.reason.reasonId !== -1 && this.waitListRegistrationDateOption && this.waitListRegistrationDateOption.value === WaitListRegistrationDateOptionValues.DATE_TIME;
  }

  checkShowChooseTimes(waitListRegistrationTimeOptionValue: WaitListRegistrationTimeOptionValues) {
    if (waitListRegistrationTimeOptionValue === WaitListRegistrationTimeOptionValues.BOOKED_TIMES)
      return this.waitList.reason && this.waitList.reason.reasonId !== -1 && this.waitListRegistrationDateOption && this.waitListRegistrationDateOption.value === WaitListRegistrationDateOptionValues.DATE_TIME && this.waitListRegistrationTimeOption && this.waitListRegistrationTimeOption.value === WaitListRegistrationTimeOptionValues.BOOKED_TIMES;
    else if (waitListRegistrationTimeOptionValue === WaitListRegistrationTimeOptionValues.CUSTOM_TIME)
      return this.waitList.reason && this.waitList.reason.reasonId !== -1 && this.waitListRegistrationDateOption && this.waitListRegistrationDateOption.value === WaitListRegistrationDateOptionValues.DATE_TIME && this.waitListRegistrationTimeOption && this.waitListRegistrationTimeOption.value === WaitListRegistrationTimeOptionValues.CUSTOM_TIME;
  }

  checkShowAddTimeButton(){
    return this.waitList.reason && this.waitList.reason.reasonId !== -1 && this.waitListRegistrationDateOption && this.waitListRegistrationDateOption.value === WaitListRegistrationDateOptionValues.DATE_TIME && this.waitListRegistrationTimeOption && (this.waitListRegistrationTimeOption.value === this.waitListRegistrationTimeOptionValues.CUSTOM_TIME || this.waitListRegistrationTimeOption.value === this.waitListRegistrationTimeOptionValues.ALL_DAY);
  }

  addingTimeDismissed() {
    this.addingTime = false;
    this.addedTime = false;
    this.errorAddingTime = false;
  }

  addCustomTimeSlot() {
    this.addingTime = true;
    this.customTimeSlot.createCustomTimeSlotFromWaitList(this.waitList, this.militaryTimeConversion, this.preference.timeFormat24Hrs, this.timeService);
    this.storeTimeSlot(this.customTimeSlot);
    this.configureOnloadAndChanges(this.waitList);
  }

  configureWaitListBaseProps() {
    this.waitList = new WaitList();
    const dateForAppt = new Date();
    const utcDate = new Date(Date.UTC(dateForAppt.getFullYear(), dateForAppt.getMonth(), dateForAppt.getDate()));
    this.waitList.startDate = utcDate;
    this.waitList.waitListDateList = [];
    this.setUpWaitListChoices();
  }

  loadForm() {
    if (this.preference && this.preference.booleanMap && this.preference.booleanMap.allowWaitListRegistrationOutsideOfAvailability)
      this.waitListRegistrationTimeOptions = [this.specificTimeValueOption, this.customTimeRangeValueOption, this.allDayTimeRangeValueOption];
    else
      this.waitListRegistrationTimeOptions = [this.specificTimeValueOption];
    this.selectedClient = this.clientAccountService.setSelectedClientOnComponent(this.preference);
    this.availabilityService.waitListMode = true;
    this.setTextProperties();
    this.configureWaitListBaseProps();
  }

  ngOnInit() {
    this.waitListOptionsLoading = true;
    if (this.preference.waitListFirstAvailable)
      this.waitListRegistrationDateOptions = [this.byDateValueOption, this.firstAvailableValueOption];
    else
      this.waitListRegistrationDateOptions = [this.byDateValueOption];
    this.loadForm();
  }

  ngOnDestroy() {
    this.availabilityService.clearCurrentEvent();
    if (this.joinWaitListHeaderClickSubscription)
      this.joinWaitListHeaderClickSubscription.unsubscribe();
  }

}
